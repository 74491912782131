<template>
  <v-main v-if="lvl == 1" class="ma-0 pa-0">
    <v-container fluid class="text-right ma-0 pa-0">
      <v-row>
        <v-col>
          <v-dialog v-model="dialog" max-width="400px">
            <template v-slot:activator="{ on }">
              <v-btn class="mx-3 ma-0" color="white" fab v-on="on" elevation=0><v-icon color="#ab1d66" x-large>add</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.name" class="right-input" label="품명" />
                  </v-col>
                  <v-col cols="6">
                    <v-select :items="grades" item-text="name" item-value="grade" v-model="editedItem.grade" class="right-input" label="등급" />
                  </v-col>
                  <v-col cols="6">
                    <v-select :items="frozens" item-text="name" item-value="frozen" v-model="editedItem.frozen" class="right-input" label="냉장/냉동" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="editedItem.price" class="right-input" label="가격" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="editedItem.price100" class="right-input" label="단가/100g" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.shipment" class="right-input" label="배송료" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.link" class="right-input" label="링크" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.comment" class="right-input" label="코멘트" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" class="white--text" small @click="close">취소</v-btn>
                <v-btn color="blue darken-1" class="white--text" small @click="save">저장</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-card class="pa-3 ma-2 text-left" flat>
        <v-row dense>
          <v-col cols="3" >
            <div class="headline font-weight-bold pa-1 pb-0">닭가슴살</div>
            <div class="subtitle caption pa-1">
              <div class="desc_title">
                닭가슴살 관리자 화면
              </div>
            </div>
          </v-col>
          <v-col cols="9" >

            <v-container fluid v-if="loading">
              <v-row dense>
                <v-col cols="12">
                  <v-row class="align-center justify-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-card v-for="(each, idx) in list" :key="each._id"  outlined class="body-2 mb-2 pa-2 pt-1 pb-1 caption">
              <v-row dense >
                <v-col cols="10">
                  <v-icon v-if="each.grade == 1" color="red">looks_one</v-icon>
                  <v-icon v-if="each.grade == 2" color="yellow">looks_two</v-icon>
                  <v-icon v-if="each.grade == 3" color="green">looks_3</v-icon>
                  <v-icon v-if="each.grade == 4" color="blue">looks_4</v-icon>
                  <v-icon v-if="each.grade == 5" color="violett">looks_5</v-icon>
                  <v-icon v-if="each.grade == 6" color="grey">looks_6</v-icon>
                  <v-icon v-if="each.grade == 7" color="grey">looks_7</v-icon>
                  <v-btn outlined color="primary" v-if="each.frozen == 2" xSmall >냉동</v-btn>
                  <v-btn outlined color="warning" v-if="each.frozen == 1" xSmall >냉장</v-btn>
                  {{ each.name }}
                </v-col>
                <v-col cols="2">
                  <v-btn icon color="black" small @click="edit_chicken(idx, each)">
                    <v-icon color="blue" small>edit</v-icon>
                  </v-btn>
                  <v-btn icon color="black" small @click="del_chicken(each._id)">
                    <v-icon color="red" small>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" class="caption">
                  <v-row dense  justify="center" class="mt-1">
                    <v-col>
                      가격: {{ each.price }} ( 100g당: {{ each.price100 }} )
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="caption">
                  <v-row dense  justify="center" class="mt-1">
                    <v-col>
                      <span class="caption">배송비: {{ each.shipment }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-btn color="primary" text :href="each.link" target="_blank">
                    <v-icon>link</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <div v-html="each.comment"></div>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import '../../common/common.js';

import { mapGetters } from 'vuex';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      loading: true,
      dialog: false,
      list: [],
      headers: [
        {
          text: '제품',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '등급', value: 'grade' },
        { text: '냉장/냉동', value: 'frozen' },
        { text: '가격', value: 'price' },
        { text: '단가/100g', value: 'price100' },
        { text: '배송료', value: 'shipment' },
        { text: '링크', value: 'link' },
        { text: '코멘트', value: 'comment' },
        { text: '', value: 'action', sortable: false },
      ],
      grades: [
        { name: 'A',  grade: 1 },
        { name: 'A-', grade: 2 },
        { name: 'B',  grade: 3 },
        { name: 'B-', grade: 4 },
        { name: 'C',  grade: 5 },
        { name: 'D',  grade: 6 },
        { name: 'E',  grade: 7 },
      ],
      frozens: [
        { name: '냉장', frozen: 1 },
        { name: '냉동', frozen: 2 },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        grade: 0,
        frozen: 0,
        price: 0,
        price100: 0,
        shipment: 0,
        link: 0,
        comment: 0,
      },
      defaultItem: {
        name: '',
        grade: 0,
        frozen: 0,
        price: 0,
        price100: 0,
        shipment: 0,
        link: 0,
        comment: 0,
      },
      file: null,
      selectedSheet: 'Sheet1',
      imported_data: [],
      export_data: [],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",

    })
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    this.load_info();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    load_info: async function() {
      if ( firebase.auth().currentUser ) {
        // 이전 정보가 있는지 확인
        const id_token = await firebase.auth().currentUser.getIdToken();
        const chickens = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if (chickens.data.length>0) {
          this.list = chickens.data;
        }

        this.loading = false;
      } else {
        setTimeout(this.load_info, 500);
      }

    },
    del_chicken: async function(_id) {

      let index = 0;
      for ( let each of this.list ) {
        if ( each._id == _id ) {
          break;
        }
        index++;
      }

      confirm('삭제하시겠습니까?') && this.list.splice(index, 1);

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
          data: {
            email: this.user.data.email,
            chick_id: _id,
          },
          headers: {'id_token': id_token},
      });

      // this.move();
    },
    edit_chicken: async function(idx, item) {

      this.editedIndex = idx;

      this.editedItem = item;
      //this.editedItem.category = item.category;

      // console.log(this.editedItem.category);
      // console.log(this.categories);

      this.dialog = true;
    },
    async upload_excel(e) {
      if ( !this.file ) {
        return alert('파일을 선택해 주십시오.');
      }
      let f = this.file;
      let reader = new FileReader();
      let obj = this;
      reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = obj.$XLSX.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        let json_worksheet = obj.$XLSX.utils.sheet_to_json(worksheet);

        for ( let each in json_worksheet ) {
          let temp  = {name: '', grade: 0, frozen: 0, price: 0, price100: 0, shipment: '', link: 0, comment: ''};
          temp.name = json_worksheet[each]['제품'];

          switch ( json_worksheet[each]['등급'] ) {
            case 'A':
              temp.grade = 1;
              break;
            case 'B':
              temp.grade = 2;
              break;
            case 'C':
              temp.grade = 3;
              break;
            case 'D':
              temp.grade = 4;
              break;
            case 'E':
              temp.grade = 5;
              break;
            case 'F':
              temp.grade = 6;
              break;
            default:
              temp.grade = json_worksheet[each]['등급'];
              break;
          }

          if ( json_worksheet[each]['냉장/냉동'] == '냉장' ) {
            temp.frozen = '1';
          } else if ( json_worksheet[each]['냉장/냉동'] == '냉동' ) {
            temp.frozen = '2';
          } else {
            temp.frozen = json_worksheet[each]['냉장/냉동'];
          }
          if ( json_worksheet[each]['가격'] != undefined ) {
            temp.price = json_worksheet[each]['가격'];
          } else {
            temp.price = json_worksheet[each][" 가격 "];
          }
          if ( json_worksheet[each]['단가/100g'] != undefined ) {
            temp.price100 = json_worksheet[each]['단가/100g'];
          } else {
            temp.price100 = json_worksheet[each][" 단가/100g "];
          }

          temp.shipment = json_worksheet[each]['배송료'];
          temp.link = json_worksheet[each]['링크'];

          if ( json_worksheet[each]['코멘트'] != undefined ) {
            temp.comment = json_worksheet[each]['코멘트'];
          } else {
            temp.comment = '';
          }

          obj.imported_data.push(temp);
        }
      }

      reader.readAsArrayBuffer(f);
      this.list = this.imported_data

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/addImport', {
        email: this.user.data.email,
        data: this.imported_data
      }, {
        headers: {'id_token': id_token},
      })

      this.file = null;
      this.imported_data = [];
    },
    async download_excel(e) {
      let temp_array = ['제품', '등급', '냉장/냉동', '가격', '단가/100g', '배송료', '링크', '코멘트'];
      this.export_data.push(temp_array);
      for ( let each of this.list ) {
        temp_array = [];
        temp_array.push(each.name);
        temp_array.push(each.grade);
        temp_array.push(each.frozen);
        temp_array.push(each.price);
        temp_array.push(each.price100);
        temp_array.push(each.shipment);
        temp_array.push(each.link);
        temp_array.push(each.comment);
        this.export_data.push(temp_array)
      }

      let wb = this.$XLSX.utils.book_new();
      let newWorksheet = this.$XLSX.utils.aoa_to_sheet(this.export_data);
      this.$XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1');
      let download_filename = 'chicken_list('+this.user.data.email+').xlsx';
      this.$XLSX.writeFile(wb, download_filename);

      this.export_data = [];
    },
    async delete_all() {
      if ( confirm("삭제하기 전에 데이터 보관을 위해 XLSX 내보내기를 실행합니다. 저장하시겠습니까?") ) {
        this.download_excel()
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/delall', {
        email: this.user.data.email,
      }, {
        headers: {'id_token': id_token},
      });
      this.list = [];
    },
    close: function () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save: async function () {

      if (this.editedIndex > -1) {
        Object.assign(this.list[this.editedIndex], this.editedItem)
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.put(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
          email: this.user.data.email,
          _id: this.editedItem._id,
          data: this.editedItem,
        }, {
          headers: {'id_token': id_token},
        });
      } else {
        this.list.push(this.editedItem)
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
          email: this.user.data.email,
          chicken: this.editedItem,
        }, {
          headers: {'id_token': id_token},
        });
      }

      this.close()
    },
    deleteItem: async function (item) {
      const index = this.list.indexOf(item)

      const del_id = this.list[index]._id;

      confirm('삭제하시겠습니까?') && this.list.splice(index, 1);

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
          data: {
            email: this.user.data.email,
            chick_id: del_id,
          },
          headers: {'id_token': id_token},
      });
    },

  }
}
</script>

<style scoped>


.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}

</style>
